<div class="department-goals-sec">
  <div class="goals-list row" appListAnimation listClassName="goal-card">
    <div *ngFor="let item of Values?.items; let i = index" class="col-lg-4">
      <div class="goal-card list-item-hide">
        <div class="goal-icon-container">
          <img class="goal-icon" [ngSrc]="MediaUrl+item.icon?.src" width="24" height="24"/>
        </div>
        <span class="goal-title">{{ item.title }}</span>
        <span class="goal-info" [innerHTML]="item.description  | sanitizedHtml ">
        </span>
      </div>
    </div>
  </div>
</div>
