import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProjectLocationDto } from '../../../../api/Client';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-tanmya-location',
  standalone: true,
  imports: [CommonModule,NgOptimizedImage],
  templateUrl: './tanmya-location.component.html',
  styleUrl: './tanmya-location.component.scss',
})
export class TanmyaLocationComponent {
  @Input() ProjectLocation: ProjectLocationDto | undefined;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
}
