<div class="question-base">
    <div class="question-sec">
        <p class="title">{{ 'ratingTitle' | umbracoTranslate | async}}</p>
        <p class="sub-title">{{totalContentCount }}</p>
    </div>
    <div class="answer-sec">
        <div class="emoji-sec" (click)="Rate(1)">
            <span class="yes icon" [style]="(CurrentNodeRating !== null && CurrentNodeRating.rating === 1 )? 'content: url(\'/assets/images/yes_happy-active.png\')' : 'content: url(\'/assets/images/yes_happy.svg\')'"></span>
            <span class="label">{{ 'yes' | umbracoTranslate | async}}</span>
        </div>
        <div class="emoji-sec" (click)="Rate(0)">
            <span class="no icon" [style]="(CurrentNodeRating !== null && CurrentNodeRating.rating === 0 )? 'content: url(\'/assets/images/no_sad-active.png\')' : 'content: url(\'/assets/images/no_sad.svg\')'"></span>
            <span class="label">{{ 'no' | umbracoTranslate | async}}</span>
        </div>
    </div>
</div>
