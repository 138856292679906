import { AbstractControl, ValidationErrors } from '@angular/forms';

export function fileTypeValidator(
  allowedTypes: string[]
): (control: AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    const file = control.value;
    if (!file) {
      return null; // No file, no validation error
    }

    if (typeof file === 'string') {
      // Invalid if the value is a string

      const fileName = file; // Get the file name
      const extension = fileName.split('.').pop()?.toLowerCase(); // Extract and normalize the extension

      if (!extension || !allowedTypes.includes(extension)) {
        return { invalidFileExtension: true }; // Validation error for invalid extension
      }
    } else if (file instanceof File) {
      const fileType = file.type; // Get the file MIME type
      if (!allowedTypes.includes(fileType)) {
        return { invalidFileType: true }; // Validation error for invalid file type
      }
    } else {
      return { invalidFileType: true }; // Ensure it is a file type
    }

    return null; // Validation passes
  };
}
