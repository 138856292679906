<div class="projects-filter-container">
  <div class="filter-sec row">
    <div class="filter-input-sec col-12 col-lg-7">
      <span class="icon-search-input"></span>
      <input
        class="input filter"
        type="text"
        placeholder="{{ 'searchWithThreeDots' | umbracoTranslate | async }}"
        #debounceInput
        [(ngModel)]="SearchTerm"
        (keyup.enter)="Search($event)"
      />
    </div>
    <select
      [(ngModel)]="RegionId"
      class="dropdown custom-select col-12 col-lg-2"
      (change)="Search($event)"
    >
      <option [value]="null" selected>{{ "allProjects" | umbracoTranslate | async }}</option>
      <option *ngFor="let item of RegionDto; let i = index" [value]="item.id">
        {{ item.name }}
      </option>
    </select>
    <button (click)="Search($event)" class="search-btn col-12 col-lg-2">
      {{ "search" | umbracoTranslate | async }}
    </button>
  </div>
  @if (GrowthProjectItemDto && GrowthProjectItemDto!.length > 0) {
  <div
    class="projects-list-sec row grid gap-0 row-gap-lg-5"
    appListAnimation
    listClassName="item"
  >

    <div
      *ngFor="let item of GrowthProjectItemDto; let i = index"
      class="col-lg-4"
    >
      <div
        class="item list-item-hide"
        [style]="
          'background-image: url(\'' +
          MediaUrl +
          item.backgroundImage?.src +
          '\')'
        "
      >
        <div class="small-caption">
          <div class="project-info">
            <div *ngIf="item.region" class="location">
              <span class="location-icon"></span>
              <span class="location-name">{{ item.region.name }}</span>
            </div>
            <span class="project-name">{{ item.title }}</span>
          </div>
          <div class="project-icon">
            <img
              [ngSrc]="MediaUrl + item.icon?.src"
              width="92" height="61"
              alt=""
              class="project-trade-mark"
            />
          </div>
        </div>
        <div class="caption">
          <div class="location-info">
            <div *ngIf="item.region" class="location">
              <span class="location-icon"></span>
              <span class="location-name">{{ item.region.name }}</span>
            </div>
            <img [ngSrc]="MediaUrl + item.icon?.src" class="institution-icon" width="92" height="61"/>
          </div>
          <div class="caption-location-navigation">
            <span class="location-navigate"></span>
          </div>
          <div class="caption-info">
            <div class="title-container">
              <span
                class="title-icon"
                [style]="
                  'content: url(\'' + MediaUrl + item.hoverIcon?.src + '\');'
                "
              ></span>
              <span class="title"> {{ item.title }}</span>
            </div>
            <span class="info">
              {{ item.description }}
            </span>
          </div>
          <!-- <div class="caption-announcement">
            <span class="alert-icon"></span>
            <span class="announcement"
              >إعلان بدء عمليات نزع ملكيات العقار في منطقة المشروع ويجب تسليم الوثائق الرسمية من
              الملاك قبل تاريخ 10/2/2024</span
            >
          </div> -->
          <div class="caption-actions">
            <button
              [routerLink]="item.clickToAction?.url"
              class="preview-project"
            >
              {{ "knowMore" | umbracoTranslate | async }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
}
@if (!Loading && GrowthProjectItemDto.length === 0){
  <div style="text-align: center;">  {{ "sorry,noResults" | umbracoTranslate | async }}</div>

}

  <div class="pagination-sec">
    <button
      (click)="LoadMore($event)"
      *ngIf="CurrentCount < TotalCount"
      class="btn more"
    >
      {{ "more" | umbracoTranslate | async }}
    </button>
  </div>
</div>
<ngx-spinner type="ball-scale-multiple" [color]="'#0C553C'"></ngx-spinner>
