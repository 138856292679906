import { CommonModule } from '@angular/common';
import { Component, Input, input, OnInit, ViewChild } from '@angular/core';
import {
  GoogleMapsModule,
  MapInfoWindow,
  MapMarker,
} from '@angular/google-maps';
import { MediaAgenciesDto, OurLocationsDto } from '../../../api/Client';
import { UmbracoTranslatePipe } from '../../core/pipes/umbraco-translate.pipe';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { SanitizedHtmlPipe } from '../../core/pipes/sanitized-html.pipe';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-branches-map',
  standalone: true,
  imports: [
    GoogleMapsModule,
    CommonModule,
    UmbracoTranslatePipe,
    SanitizedHtmlPipe,
  ],
  templateUrl: './branches-map.component.html',
  styleUrl: './branches-map.component.scss',
})
export class BranchesMapComponent implements OnInit {
  @Input()
  public ourLocationsDto: OurLocationsDto = new OurLocationsDto();
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;
  infoContent: SafeHtml = '';

  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  public learnMoreKey: string = '';
  constructor(
    private sanitizer: DomSanitizer,
    private transalteService: TranslationService
  ) {}
  ngOnInit(): void {
    this.markerOptions = {
      animation: google.maps.Animation.DROP, // Animation style for the marker (DROP or BOUNCE)
    };

    this.transalteService.Translate('visitLocation').then((res) => {
      this.learnMoreKey = res;
    });
  }

  public toNumber(numberStr: string): number {
    return Number.parseFloat(numberStr);
  }

  onMarkerClick(marker: any, markerRef: MapMarker) {
    var htmlPlaceHolder = `
<div style="max-width: 300px; font-family: Arial, sans-serif; color: #000; text-align: center; margin: 0 auto;">
  <h3 style="margin: 0; font-size: 1rem; font-weight: bold; color: #000;">
    ${marker.title}
  </h3>
  <p style="margin: 8px 0; font-size: 0.875rem; color: #555;">
    ${marker.description}
  </p>
  <div>
    <img
      src="${this.MediaUrl + marker?.image?.src}"
      alt="Location Image"
      style="width: 200px; height: 150px; border-radius: 5px; margin: 8px auto; object-fit: cover; display: block;"
    />
  </div>
  <a
    href="${marker.googleMapsLink}"
    target="_blank"
    rel="noopener noreferrer"
    style="display: inline-block; padding: 8px 12px; background-color: #007bff; color: #fff; text-decoration: none; border-radius: 4px; font-size: 0.875rem; text-align: center; margin-top: 8px;"
  >
    ${this.learnMoreKey}
  </a>
</div>




`;
    this.infoContent = this.sanitizer.bypassSecurityTrustHtml(htmlPlaceHolder);
    this.infoWindow.open(markerRef);
  }

  defaultCenter: google.maps.LatLngLiteral = {
    lat: 24.7576889519489,
    lng: 46.6957067718203,
  };

  // Map center and zoom level
  center: google.maps.LatLngLiteral = {
    lat: 24.7576889519489,
    lng: 46.6957067718203,
  };
  zoom: number = 6;

  public changeBracnhes($event: Event) {
    var itemIndex = Number.parseInt(
      ($event.target as HTMLTextAreaElement).value
    );
    var targetItem = this.ourLocationsDto.locations![itemIndex];

    if (targetItem !== undefined) {
      this.zoom = 10;
      this.center = {
        lat: targetItem.latitude ? parseFloat(targetItem.latitude) : 0,
        lng: targetItem.longitude ? parseFloat(targetItem.longitude) : 0,
      };
    } else {
      this.zoom = 6;
      this.center = this.defaultCenter;
    }
  }

  // Object to store the updated latitude/longitude from map movements
  display: any;

  // Marker options to customize the style (custom green pin)
  markerOptions = {};

  // Map options to configure the map's zoom control position
  mapOptions = {
    zoomControl: true, // Show zoom control
    zoomControlOptions: {
      position: google.maps.ControlPosition.INLINE_END_BLOCK_END, // Position zoom control at bottom center
    },
    mapTypeControl: false, // Disable map type control
    streetViewControl: false, // Disable street view control
    fullscreenControl: true, // Disable fullscreen control
  };

  moveMap(event: google.maps.MapMouseEvent): void {
    if (event.latLng) {
      this.center = event.latLng.toJSON();
    }
  }

  move(event: google.maps.MapMouseEvent): void {
    if (event.latLng) {
      this.display = event.latLng.toJSON();
    }
  }
}
