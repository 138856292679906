import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { CommonContentService, PageKeysDto } from '../../api/Client';

@Injectable({
  providedIn: 'root',
})
export class ContentPagesServices {
  private isBrowser: boolean = true;
  constructor(private _router: Router, private _client: CommonContentService) {
    this.isBrowser = typeof window !== 'undefined';
  }

  getPathForCurrentPage(removeLang: boolean = true) {
    return decodeURIComponent(
      removeLang
        ? this._router.url.replace('/ar/', '').replace('/en/', '')
        : this._router.url
    );
  }

  amendSwappingPathForCurrentPage(swap: boolean = false) {
    const url = this._router.url.replace('/ar/', '').replace('/en/', '');
    const swaped = url.split('/').reverse().join('/');
    console.log('swaped' + swaped);

    return !swap ? url : url.split('/').reverse().join('/');
  }

  async getKeyForCurrentPage(): Promise<PageKeysDto> {
    var keys = this._client.pageKeys(this.getPathForCurrentPage());
    return await lastValueFrom(keys);
  }

  getSessionId(): string {
    if (!this.isBrowser) return '';

    const sessionIdKey = 'c-rate-s-id';
    var keyResult = localStorage.getItem(sessionIdKey);
    if (keyResult == null) {
      keyResult = this._generateSessionId();
      localStorage.setItem(sessionIdKey, keyResult);
    }
    return keyResult;
  }

  private _generateSessionId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (char) {
        const random = (Math.random() * 16) | 0;
        const value = char === 'x' ? random : (random & 0x3) | 0x8;
        return value.toString(16);
      }
    );
  }
}
