import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import {
  CommonContentService,
  ContactUsFormDto,
  ContactUsPageContentService,
  ExtraHelpDto,
  FooterSectionDto,
  IntroDto,
  LookupDto,
  MediaAgenciesDto,
  OurLocationsDto,
  SocialLinksDto,
  SubmitComplaintsAndSuggestionsDto,
} from '../../../api/Client';
import { ContentPagesServices } from '../../services/content-page-services.service';
import { environment } from '../../../environments/environment';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { fileTypeValidator } from '../../core/custom-validators/file-type-validator';
import { TranslationService } from '../../services/translation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare var grecaptcha: any;
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
  standalone: false,
  animations: [fadeInAnimation],
})
export class ContactUsComponent implements OnInit, OnDestroy {
  public IntroDto: IntroDto | undefined;
  isUploadVisible: boolean = false; // Track visibility of the upload div
  selectedImage: any = null; // Holds the selected image
  socialSectionDto = {} as SocialLinksDto;
  footerSectionDto = {} as FooterSectionDto;
  contactForm: FormGroup | null = null;
  public contactUsFormDto: ContactUsFormDto = new ContactUsFormDto();
  public complaintsAndSuggestions: LookupDto[] = [];
  public extraHelpDto: ExtraHelpDto = new ExtraHelpDto();
  public mediaAgenciesDto: MediaAgenciesDto = new MediaAgenciesDto();
  public ourLocationsDto: OurLocationsDto | null = null;
  base64String: string | null = null;
  fielName: string | null = null;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  public errorMessages: any = {};
  siteKey: string = '';
  enabled: boolean = false;
  scriptLoaded = false;
  mapsUrl: string = '';
  constructor(
    private _client: ContactUsPageContentService,
    private _cotnentService: ContentPagesServices,
    private _commonContentService: CommonContentService,
    private fb: FormBuilder,
    private transalteService: TranslationService,
    private spinner: NgxSpinnerService,
    private _toastr: ToastrService,
    private _cotnentLoadingService: CotnentLoadingService
  ) {
    this.contactForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      contactType: ['', [Validators.required]],
      phone: [
        '',
        [Validators.required, Validators.minLength(9), Validators.maxLength(9)],
      ],
      messageTitle: ['', [Validators.required]],
      messageBody: ['', [Validators.required, Validators.maxLength(500)]],
      file: ['', [fileTypeValidator(['pdf', 'png', 'jpeg', 'jpg'])]],
    });
  }
  // Function to dynamically append the reCAPTCHA script
  loadRecaptchaScript() {
    if (this.scriptLoaded || !this.siteKey) return;

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      this.scriptLoaded = true;
      console.log('reCAPTCHA script loaded successfully.');
    };

    script.onerror = () => {
      console.error('Failed to load reCAPTCHA script.');
    };
  }

  // Function to execute reCAPTCHA
  executeRecaptcha(action: string) {
    if (!this.scriptLoaded || !this.siteKey) {
      console.error(
        'reCAPTCHA script is not loaded or site key is unavailable.'
      );
      return;
    }

    grecaptcha.ready(() => {
      grecaptcha.execute(this.siteKey, { action }).then((token: string) => {
        console.log('reCAPTCHA token:', token);
        // Send token to your backend for verification
      });
    });
  }

  async ngOnInit() {
    this._commonContentService.recaptchaSettings().subscribe({
      next: async (response) => {
        this.mapsUrl = response.googleMapsKey!;
        this.siteKey = response.key!;
        this.enabled = response.enable!;

        if (response.googleMapsEnable) {
          this._client.ourLocation(currentPageUrl).subscribe({
            next: async (response) => {
              await this.loadGoogleMaps(this.mapsUrl);
              this.ourLocationsDto = response;
            },
          });
        }

        if (this.enabled) {
          this.loadRecaptchaScript();
        }
      },
      error: (err) => {
        console.error('Failed to fetch reCAPTCHA site key:', err);
      },
    });

    var currentPageUrl = this._cotnentService.getPathForCurrentPage();
    this._commonContentService.introText(currentPageUrl).subscribe({
      next: (res) => {
        this.IntroDto = res;
      },
    });
    this._client.contactUsForm(currentPageUrl).subscribe({
      next: (response) => {
        this.contactUsFormDto = response;
        this._cotnentLoadingService.setLoadingState(true);
      },
    });

    this._client.extraHelp(currentPageUrl).subscribe({
      next: (response) => {
        this.extraHelpDto = response;
      },
    });

    this._commonContentService
      .complaintsAndSuggestions(currentPageUrl)
      .subscribe({
        next: (response) => {
          this.complaintsAndSuggestions = response;
        },
      });

    this._commonContentService.footerWidget().subscribe((section) => {
      this.footerSectionDto = section;
    });
    this._commonContentService.socialLinksWidget().subscribe((section) => {
      this.socialSectionDto = section;
    });

    this._client.mediaAgencies(currentPageUrl).subscribe((section) => {
      this.mediaAgenciesDto = section;
    });

    this.errorMessages['required'] = await this.transalteService.Translate(
      'required'
    );
    this.errorMessages['maxlength'] = await this.transalteService.Translate(
      'maxlength',
      [500]
    );
    this.errorMessages['validatePhoneNumber'] =
      await this.transalteService.Translate('validatePhoneNumber');

    this.errorMessages['invalidFileType'] =
      await this.transalteService.Translate('invalidFileType');

    this.errorMessages['emailInvalid'] = await this.transalteService.Translate(
      'emailInvalid'
    );
  }

  loadGoogleMaps(apiKey: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.getElementById('google-maps-script')) {
        resolve(); // Script already loaded
        return;
      }
      const script = document.createElement('script');
      script.id = 'google-maps-script';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = () => reject('Google Maps script could not be loaded.');
      document.body.appendChild(script);
    });
  }

  ngOnDestroy() {
    this._cotnentLoadingService.setLoadingState(false);
    // Clean up if necessary
    const script = document.getElementById('google-maps-script');
    if (script) {
      script.remove();
    }

    document
      .querySelectorAll("script[src*='recaptcha/api.js']")
      .forEach((script) => script.remove());

document
  .querySelectorAll('.grecaptcha-badge')
  .forEach((script) => script.remove());

document
  .querySelectorAll('google-map')
  .forEach((script) => script.remove());

  }

  public textVlaues: string = '';
  //عدد الحروف ( الحد الأقصى: 0/500 حرف )
  public displayTextLimit() {
    this.transalteService
      .Translate('wordCounter', [
        this.contactForm?.get('messageBody')?.value.length,
        500,
      ])
      .then((result) => {
        this.textVlaues = result;
      });
  }
  public onSubmit() {
    this.contactForm!.markAllAsTouched();
    if (!this.contactForm!.valid) {
      window.scrollTo({
        top: 0, // Scroll to the top of the page
        behavior: 'smooth', // Smooth scrolling animation
      });
      return;
    }

    this.spinner.show();

    if (this.enabled) {
      grecaptcha
        .execute(this.siteKey, { action: 'contact_us_submit' })
        .then((token: string) => {
          this.submitRequest(token);
        }).catch((err :any)=>{
          this.spinner.hide();
          this._toastr.error('ReCaptchaDidNotPass');
        });
    } else {
      this.submitRequest('x');
    }
  }

  private submitRequest(token: any) {
    this._client
      .submitComplaintsAndSuggestions(
        SubmitComplaintsAndSuggestionsDto.fromJS({
          firstName: this.contactForm?.controls['firstName'].value,
          lastName: this.contactForm?.controls['lastName'].value,
          email: this.contactForm?.controls['email'].value,
          phoneNumber:
            this.contactForm?.controls['phone'].value.internationalNumber,
          type: this.contactForm?.controls['contactType'].value,
          title: this.contactForm?.controls['messageTitle'].value,
          message: this.contactForm?.controls['messageBody'].value,
          recapchaToken: token,
          attachment: {
            fileName: this.fielName,
            fileContent: this.base64String,
          },
        })
      )
      .subscribe({
        next: (result) => {
          this.spinner.hide();

          if (result.isSuccessful) {
            this.transalteService.Translate('submitDone').then((x) => {
              this._toastr.success(x);
            });

            this.contactForm?.reset();
            this.base64String = '';
            this.fielName = '';
          }

          if (!result.isSuccessful)
            this.transalteService
              .Translate(result.resultDescription!)
              .then((x) => {
                this._toastr.error(x);
              });
        },
        complete: () => {},
        error: (error) => {
          this.spinner.hide();

          if (
            error.error.validationErrors !== undefined &&
            error.error.validationErrors !== null &&
            error.error.validationErrors.length > 0
          ) {
            error.error.validationErrors.forEach((x: any) => {
              this.transalteService.Translate(x.message).then((x) => {
                this._toastr.error(x);
              });
            });
          } else {
            this.transalteService.Translate('submitError').then((x) => {
              this._toastr.error(x);
            });
          }
        },
      });
  }

  getErrorMessage(field: string) {
    const control = this.contactForm!.get(field);
    if (control?.hasError('required')) {
      return this.errorMessages.required;
    }

    if (control?.hasError('maxlength')) {
      const requiredLength = control.getError('maxlength').requiredLength;
      return this.errorMessages.maxlength;
    }

    if (control?.hasError('validatePhoneNumber')) {
      return this.errorMessages.validatePhoneNumber;
    }

    if (control?.hasError('minlength')) {
      const requiredLength = control.getError('minlength').requiredLength;
      return this.errorMessages.minlength;
    }

    if (control?.hasError('email')) {
      return this.errorMessages.emailInvalid;
    }

    if (control?.hasError('pattern')) {
      return this.errorMessages.pattern;
    }

    if (
      control?.hasError('invalidFileExtension') ||
      control?.hasError('invalidFileType')
    ) {
      return this.errorMessages.invalidFileType;
    }

    return null;
  }

  convertFileToBase64(event: Event): Promise<string> {
    return new Promise((resolve, reject) => {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const reader = new FileReader();
        const file = input.files[0];
        this.fielName = file.name;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.base64String = reader.result as string;
          this.contactForm!.get('file')?.updateValueAndValidity();
          return resolve(reader.result as string);
        };
        reader.onerror = (error) => reject(error);
      } else {
        this.fielName = '';
        reject(new Error('No file selected or input is null.'));
      }
    });
  }

  // Toggle the visibility of the upload div
  toggleUploadDiv(): void {
    this.isUploadVisible = !this.isUploadVisible;
  }

  // Handle the file drag over
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const container = event.target as HTMLElement;
    container.classList.add('drag-over');
  }

  // Handle drag leave event
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const container = event.target as HTMLElement;
    container.classList.remove('drag-over');
  }

  // Handle file drop
  onDrop(event: DragEvent): Promise<string> {
    event.preventDefault();
    event.stopPropagation();
    const container = event.target as HTMLElement;
    container.classList.remove('drag-over');

    return new Promise((resolve, reject) => {
      const input = event.dataTransfer;
      if (input && input.files && input.files.length > 0) {
        const reader = new FileReader();
        const file = input.files[0];
        this.fielName = file.name;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.base64String = reader.result as string;
          this.contactForm!.get('file')?.updateValueAndValidity();
          return resolve(reader.result as string);
        };
        reader.onerror = (error) => reject(error);
      } else {
        this.fielName = '';
        reject(new Error('No file selected or input is null.'));
      }
    });
  }

}
