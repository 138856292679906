<div class="board-members-sec">
  <span class="title">{{ BoardMembers?.title }}</span>
  <div class="card-members-list-sec" appListAnimation listClassName="member-card">
    <div *ngIf="BoardMembers?.members?.[0]" class="prisdent-card member-card list-item-hide">
      <img [ngSrc]="MediaUrl + BoardMembers?.members?.[0]?.image?.src" alt="" width="80" height="86"/>
      <div class="info-sec">
        <span class="intro">{{ BoardMembers?.members?.[0]?.brief }}</span>
        <span class="name">{{ BoardMembers?.members?.[0]?.fullName }}</span>
        <span class="position text-black">{{ BoardMembers?.members?.[0]?.jobTitle }}</span>
      </div>
    </div>

    <div class="member-cards-list">
      <div class="row justify-content-evenly">
        <div
          class="col-xl-5 col-lg-6 mb-2"
          *ngFor="let member of BoardMembers?.members?.slice(1); let i =index">
          <div class="member-card list-item-hide">
            <img [ngSrc]="MediaUrl+member.image?.src" alt="" width="75" height="86" />
            <div class="info-sec">
              <span class="position">{{member.jobTitle}}</span>
              <span class="intro">{{member.brief}}  </span>
              <span class="name">{{member.fullName}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
