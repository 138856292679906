<div [@fadeIn]>
  <app-page-title-info
    *ngIf="IntroDto"
    [BackgroundImage]="IntroDto?.image"
    [Title]="IntroDto?.title ?? ''"
    [Description]="IntroDto?.description ?? ''"
  ></app-page-title-info>

  <div class="supervisor-sec" *ngIf="GeneralSupervisorDto && GeneralSupervisorDto.enabled">
    <div class="content">
      <div class="title">
        {{ GeneralSupervisorDto?.title }}
      </div>
      <div [innerHtml]="GeneralSupervisorDto?.details | sanitizedHtml"></div>
    </div>
  </div>
  <app-board-members
    *ngIf="BoardMembersDto && BoardMembersDto.enabled"
    [BoardMembers]="BoardMembersDto"
  ></app-board-members>
  <app-executive-managment
    *ngIf="ExecutiveManagementDto && ExecutiveManagementDto.enabled"
    [ExecutiveManagement]="ExecutiveManagementDto"
  ></app-executive-managment>

  <app-departments-goals
    *ngIf="ValuesDto && ValuesDto.items?.length! ?? 0>0"
    [Values]="ValuesDto"
  ></app-departments-goals>

  <div class="d-flex flex-column gap-20">
    <!-- <slider-mission-goals></slider-mission-goals> -->
    <app-slider-mission-goals
      *ngIf="RolesDto"
      [Roles]="RolesDto!"
    ></app-slider-mission-goals>
    <!-- <social-growth-word></social-growth-word> -->
    <div
      *ngIf="RolesDto?.featuredContent && RolesDto?.featuredContent?.image?.src != null"
      class="social-growth-word-section row hide-fade"
      appScrollDetect
    >
      <img
        class="social-growth-img col-lg-6"
        [ngSrc]="MediaUrl + RolesDto?.featuredContent?.image?.src" width="515" height="490"
        alt=""
      />
      <div class="social-growth-word-content col-lg-5">
        <span class="title">{{ RolesDto?.featuredContent?.title }}</span>
        <span class="content">
          {{ RolesDto?.featuredContent?.description }}
        </span>
      </div>
    </div>
  </div>

  <!-- <status-and-numbers></status-and-numbers> -->
  <app-status-and-numbers
    *ngIf="NumbersAndFactsDto"
    [NumbersAndFacts]="NumbersAndFactsDto"
  ></app-status-and-numbers>
  <!-- partners section  -->
  <app-partners></app-partners>
  <app-page-rating></app-page-rating>
</div>
