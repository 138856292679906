import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, forkJoin, of } from 'rxjs';
import {
  AboutUsPageContentService,
  BoardMembersDto,
  CommonContentService,
  ExecutiveManagementDto,
  GeneralSupervisorDto,
  IntroDto,
  NumbersAndFactsDto,
  OurRoleDto,
  ValuesDto,
} from '../../../api/Client';
import { ContentPagesServices } from '../../services/content-page-services.service';
import { environment } from '../../../environments/environment';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
  standalone: false,
  animations: [fadeInAnimation],
})
export class AboutUsComponent implements OnInit, OnDestroy {
  public IntroDto: IntroDto | undefined;
  public GeneralSupervisorDto: GeneralSupervisorDto | undefined;
  public BoardMembersDto: BoardMembersDto | undefined;
  public ExecutiveManagementDto: ExecutiveManagementDto | undefined;
  public ValuesDto: ValuesDto | undefined;
  public RolesDto: OurRoleDto | undefined;
  public NumbersAndFactsDto: NumbersAndFactsDto | undefined;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  constructor(
    private _client: AboutUsPageContentService,
    private _contentPagesServices: ContentPagesServices,
    private _common: CommonContentService,
    private _cotnentLoadingService: CotnentLoadingService
  ) {}
  ngOnInit(): void {
    var currentPagePath = this._contentPagesServices.getPathForCurrentPage();
    forkJoin({
      intoDto: this._common.introText(currentPagePath),
      boardMembersDto: this._client.boardMembers(currentPagePath),
      valuesDto: this._client.valuesWidget(currentPagePath),
      rolesDto: this._client.ourRoleWidget(currentPagePath),
      numberAndFactsDto: this._client.numberAndFactsWidget(currentPagePath),
      executiveManagementDto: this._client.executiveManagement(currentPagePath),
      generalSupervisorDto: this._client
        .generalSupervisorMessage(currentPagePath)
        .pipe(
          catchError((error) => {
            return of(null);
          })
        ),
    }).subscribe(
      async ({
        intoDto,
        generalSupervisorDto,
        boardMembersDto,
        executiveManagementDto,
        valuesDto,
        rolesDto,
        numberAndFactsDto,
      }) => {
        this.IntroDto = intoDto;
        this.ValuesDto = valuesDto;
        this.GeneralSupervisorDto = generalSupervisorDto!;
        this.BoardMembersDto = boardMembersDto;
        this.ExecutiveManagementDto = executiveManagementDto;
        this.RolesDto = rolesDto;
        this.NumbersAndFactsDto = numberAndFactsDto;

        this._cotnentLoadingService.setLoadingState(true);
      }
    );
  }
  ngOnDestroy(): void {
    this._cotnentLoadingService.setLoadingState(false);
  }
}
