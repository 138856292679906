<div [@fadeIn]>
  <app-page-title-info
    *ngIf="IntroDto"
    [BackgroundImage]="IntroDto?.image"
    [Title]="IntroDto?.title ?? ''"
    [Description]="IntroDto?.description ?? ''"
  ></app-page-title-info>
  <div class="contact-us-container">
    <div class="row">
      <div class="col-lg-6">
        <div class="contact-us-body">
          <div class="contact-us-header">
            <span class="title">{{ contactUsFormDto.title }}</span>
            <span class="sub-title">{{ contactUsFormDto.description }}</span>
          </div>
          <div class="contact-us-form">
            <form
              class="row g-3"
              [formGroup]="contactForm!"
              (ngSubmit)="onSubmit()"
            >
              <div class="col-6">
                <label for="firstName" class="form-label"
                  >{{ "firstName" | umbracoTranslate | async }}
                </label>
                <input
                  formControlName="firstName"
                  type="text"
                  class="form-control"
                  id="firstName"
                />
                <div
                  *ngIf="
                    contactForm!.get('firstName')?.invalid &&
                    contactForm!.get('firstName')?.touched
                  "
                  class="error"
                >
                  {{ getErrorMessage("firstName") }}
                </div>
              </div>
              <div class="col-6">
                <label for="lastName" class="form-label"
                  >{{ "lastName" | umbracoTranslate | async }}
                </label>
                <input
                  formControlName="lastName"
                  type="text"
                  class="form-control"
                  id="lastName"
                />
                <div
                  *ngIf="
                    contactForm!.get('lastName')?.invalid &&
                    contactForm!.get('lastName')?.touched
                  "
                  class="error"
                >
                  {{ getErrorMessage("lastName") }}
                </div>
              </div>
              <div class="col-12">
                <label for="inputAddress" class="form-label">{{
                  "email" | umbracoTranslate | async
                }}</label>
                <input
                  formControlName="email"
                  type="email"
                  class="form-control"
                  id="email"
                />
                <div
                  *ngIf="
                    contactForm!.get('email')?.invalid &&
                    contactForm!.get('email')?.touched
                  "
                  class="error"
                >
                  {{ getErrorMessage("email") }}
                </div>
              </div>

              <div class="col-12">
                <label class="form-check-label" for="gridCheck">
                  {{ "contacType" | umbracoTranslate | async }}
                </label>
                <div class="form-check m-0 p-0 mt-3">
                  <div
                    *ngFor="let item of complaintsAndSuggestions; let i = index"
                    class="form-check m-0 me-2 mb-3 form-check-inline"
                  >
                    <label class="form-check-label" [for]="item.id">{{
                      item.name
                    }}</label>
                    <input
                      class="form-check-input"
                      type="radio"
                      formControlName="contactType"
                      name="contactType"
                      [value]="item.id"
                      [id]="item.id"
                    />
                  </div>
                  <div
                    *ngIf="
                      contactForm!.get('contactType')?.invalid &&
                      contactForm!.get('contactType')?.touched
                    "
                    class="error"
                  >
                    {{ getErrorMessage("contactType") }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <label for="inputAddress2" class="form-label">
                  {{ "phoneNumber" | umbracoTranslate | async }}
                </label>

                <!-- [phoneValidation]="true" [maxLength]="15" -->

                <ngx-intl-tel-input
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  searchCountryPlaceholder="{{
                    'phoneNumber' | umbracoTranslate | async
                  }}"
                  [separateDialCode]="true"
                  [preferredCountries]="['sa']"
                  name="phone"
                  formControlName="phone"
                ></ngx-intl-tel-input>

                <div
                  *ngIf="
                    contactForm!.get('phone')?.invalid &&
                    contactForm!.get('phone')?.touched
                  "
                  class="error"
                >
                  {{ getErrorMessage("phone") }}
                </div>
              </div>
              <div class="col-12" appScrollDetect class="hide-fade">
                <label for="inputAddress" class="form-label">
                  {{ "messageTitle" | umbracoTranslate | async }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="inputAddress"
                  formControlName="messageTitle"
                />
                <div
                  *ngIf="
                    contactForm!.get('messageTitle')?.invalid &&
                    contactForm!.get('messageTitle')?.touched
                  "
                  class="error"
                >
                  {{ getErrorMessage("messageTitle") }}
                </div>
              </div>
              <div class="col-12" appScrollDetect class="hide-fade">
                <label for="inputAddress2" class="form-label"
                  >{{ "messageBody" | umbracoTranslate | async }}
                </label>
                <textarea
                  (keyup)="displayTextLimit()"
                  type=""
                  class="form-control"
                  rows="10"
                  id="inputAddress2"
                  formControlName="messageBody"
                ></textarea>
                <p class="max-letters">{{ textVlaues }}</p>

                <div
                  *ngIf="
                    contactForm!.get('messageBody')?.invalid &&
                    contactForm!.get('messageBody')?.touched
                  "
                  class="error"
                >
                  {{ getErrorMessage("messageBody") }}
                </div>
              </div>
              <div class="col-12" appScrollDetect class="hide-fade">
                <div class="toggle-wrapper">
                  <div class="open">
                    <button
                      type="button"
                      class="upload-btn"
                      (click)="toggleUploadDiv()"
                    >
                      <div
                        class="open-upload"
                        [ngStyle]="{
                          'background-image': isUploadVisible
                            ? 'url(/assets/images/close-square.svg)'
                            : 'url(/assets/images/add-square.svg)',
                          'background-size': 'cover',
                          'background-position': 'center'
                        }"
                      ></div>
                    </button>
                    <label for="upload-input"
                      >{{ "attachments" | umbracoTranslate | async }}
                    </label>
                  </div>
                  <div
                    *ngIf="isUploadVisible"
                    type="button"
                    class="close-btn"
                    (click)="toggleUploadDiv()"
                  >
                    <i class="fa fa-times"></i>
                  </div>
                </div>

                <div *ngIf="isUploadVisible" class="upload-container">
                  <div
                    class="upload-content"
                    (dragover)="onDragOver($event)"
                    (dragleave)="onDragLeave($event)"
                    (drop)="onDrop($event)"
                    (click)="fileInput.click()"
                  >
                    <input
                      formControlName="file"
                      #fileInput
                      type="file"
                      accept="image/*"
                      (change)="convertFileToBase64($event)"
                      hidden
                    />

                    <div class="upload-content-inner">
                      <i class="upload-icon"></i>
                      <div class="upload-sec-text">
                        @if (fielName) {
                        <span class="upload-sub-text text-black">{{
                          fielName
                        }}</span>
                        } @else{
                        <span class="upload-text">
                          {{ "uploadHint" | umbracoTranslate | async }}
                        </span>
                        <span class="upload-sub-text"
                          >{{ "dragHint" | umbracoTranslate | async }}
                        </span>
                        }
                        <span class="upload-files-limit"
                          >{{ "formatHint" | umbracoTranslate | async }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <!-- Show the selected image preview -->
                  <div *ngIf="selectedImage">
                    <img
                      [ngSrc]="selectedImage"
                      alt="Selected Image"
                      class="preview-image"
                      width="200"
                      height="150"
                    />
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  contactForm!.get('file')?.invalid &&
                  contactForm!.get('file')?.touched
                "
                class="error"
              >
                {{ getErrorMessage("file") }}
              </div>
              <div class="col-12 hide-fade" appScrollDetect>
                <button class="btn send-form">
                  {{ "send" | umbracoTranslate | async }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="contact-us-info">
          <div class="more-ways">
            <div class="header">
              <div class="title">{{ extraHelpDto.title }}</div>
              <div class="sub-title">
                {{ extraHelpDto.description }}
              </div>
            </div>
            <div class="info-cards-list">
              <div class="info-card">
                <label for="" class="label">
                  {{ "email" | umbracoTranslate | async }}
                </label>
                <span class="info">
                  <span class="info-icon message-icon"> </span>
                  {{ footerSectionDto.supportEmail }}
                </span>
              </div>
              <div class="info-card">
                <label for="" class="label">
                  {{ "uniNumber" | umbracoTranslate | async }}
                </label>
                <span class="info">
                  <span class="info-icon phone-icon"> </span>
                  {{ footerSectionDto.supportPhoneNumber }}
                </span>
              </div>
              <div class="info-card">
                <label for="" class="label">
                  {{ "socialMediaChannels" | umbracoTranslate | async }}
                </label>
                <span class="social-links">
                  @if (socialSectionDto?.socialLinks?.length ??0 > 0) { @for
                  (logo of socialSectionDto.socialLinks; track $index) {
                  <span class="social-logo">
                    <a
                      *ngIf="logo.type == 0"
                      class="list-item"
                      [routerLink]="logo.url"
                      [target]="logo.target!"
                      ><img [ngSrc]="MediaUrl + logo.icon?.src" [alt]="logo.name" width="16" height="16"
                    /></a
                    >
                    <a
                      *ngIf="logo.type == 2"
                      class="list-item"
                      [href]="logo.url"
                      [target]="logo.target!"
                      ><img [ngSrc]="MediaUrl + logo.icon?.src" [alt]="logo.name" width="16" height="16"
                    /></a
                    >
                  </span>
                  } }
                </span>
              </div>
            </div>
          </div>
          @if(!!mediaAgenciesDto.title && !!mediaAgenciesDto.email) {
          <div class="channels">
            <div class="header">
              <div class="title">{{ mediaAgenciesDto.title }}</div>
              <div class="sub-title">
                {{ mediaAgenciesDto.description }}
              </div>
            </div>
            <div class="info-cards-list">
              <div class="info-card">
                <label for="" class="label">
                  {{ "email" | umbracoTranslate | async }}</label
                >
                <span class="info">
                  <span class="info-icon" style="background-color: black">
                  </span>
                  {{ mediaAgenciesDto.email }}
                </span>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
<div class="branch-map" *ngIf="ourLocationsDto">
  <app-branches-map [ourLocationsDto]="ourLocationsDto"></app-branches-map>
</div>

<app-page-rating></app-page-rating>

<ngx-spinner type="ball-scale-multiple" [color]="'#0C553C'"></ngx-spinner>
