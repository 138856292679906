<div class="slider-growth-missions" [@fadeIn]>
  <div class="container-fluid row">

    <!-- Indicator Section (Vertical Dashes) -->
    <div class="indicator-container col-lg-1">
      @for (paragraph of paragraphs; track $index) {
      <div
        class="indicator"
        [class.active]="activeIndex === $index"
        (click)="setActive($index)">
        |
      </div>
    }
    </div>

    <!-- Content Section (Paragraphs) -->
    <div class="content-container col-lg-5">
      @for (paragraph of paragraphs; track $index) {
      <div
        class="paragraph"
        [class.active]="activeIndex === $index"
        (click)="setActive($index)">
        <h5 class="title"> {{titles[$index]}} </h5>
        <p class="content" [innerHTML]="paragraph | sanitizedHtml" ></p>
      </div>
    }
    </div>

    <!-- Image Section -->
    <div class="image-container col-lg-5">
      @for (image of images; track $index) {

      <img
        class="image"
        [class.active]="activeIndex === $index"
        [ngSrc]="image.src" width="615" height="630"
        [alt]="image.alt">
      }
    </div>


  </div>
</div>

