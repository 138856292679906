import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {  CommonContentService, PartnersDto } from '../../../../api/Client';
import { ListAnimationDirective } from '../../../core/pipes/list-animation.directive';

@Component({
  selector: 'app-partners',
  standalone: true,
  imports: [CommonModule,ListAnimationDirective,NgOptimizedImage],
  templateUrl: './partners.component.html',
  styleUrl: './partners.component.scss',
})
export class PartnersComponent implements OnInit {

  constructor(private _commonContentService: CommonContentService) {}
  public PartnersObject: PartnersDto | null = null;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  ngOnInit(): void {
    this._commonContentService.ourPartnersWidget().subscribe(response => {
      this.PartnersObject = response;
    });
  }
}
