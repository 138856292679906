import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';

import { ContentPagesServices } from '../../services/content-page-services.service';
import {
  AboutProjectDto,
  GrowthProjectPageContentService,
  GrowthProjectsItemIntroDto,
  NumbersAndFactsDto,
  ProjectLocationDto,
} from '../../../api/Client';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';

@Component({
  selector: 'app-growth-project',
  templateUrl: './growth-project.component.html',
  styleUrl: './growth-project.component.scss',
  standalone: false,
  animations: [fadeInAnimation],
})
export class GrowthProjectComponent implements OnInit, OnDestroy {
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  public IntroDto?: GrowthProjectsItemIntroDto;
  public AboutProjectDto?: AboutProjectDto;
  public NumbersAndFactsDto?: NumbersAndFactsDto;
  public ProjectLocationDto?: ProjectLocationDto;
  constructor(
    private _client: GrowthProjectPageContentService,
    private _contentPagesServices: ContentPagesServices,
    private _cotnentLoadingService: CotnentLoadingService
  ) {}
  ngOnInit(): void {
    var currentPagePath = this._contentPagesServices.getPathForCurrentPage();
    forkJoin({
      intoDto: this._client.intro(currentPagePath),
      aboutProjectDto: this._client.aboutProject(currentPagePath),
      numbersAndFactsDto: this._client.projectNumbersAndFacts(currentPagePath),
      projectLocationDto: this._client.projectLocation(currentPagePath),
    }).subscribe(
      async ({
        intoDto,
        aboutProjectDto,
        numbersAndFactsDto,
        projectLocationDto,
      }) => {
        this.IntroDto = intoDto;
        this.AboutProjectDto = aboutProjectDto;

        this.moveFirstToLast();
        
        this.NumbersAndFactsDto =
          numbersAndFactsDto.items !== undefined
            ? numbersAndFactsDto
            : undefined;
        this.ProjectLocationDto =
          projectLocationDto.clickToAction !== undefined &&
          projectLocationDto.image !== undefined
            ? projectLocationDto
            : undefined;
        this._cotnentLoadingService.setLoadingState(true);
      }
    );
  }
  moveFirstToLast() {
    if (this.AboutProjectDto?.images && this.AboutProjectDto?.images.length > 0) {   
      this.AboutProjectDto?.images.push(this.AboutProjectDto?.images.shift()!); // move first to last
    }
  }
  
  
  ngOnDestroy(): void {
    this._cotnentLoadingService.setLoadingState(false);
  }
}
