import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NumbersAndFactsDto } from '../../../../api/Client';
import { environment } from '../../../../environments/environment';
import { ListAnimationDirective } from '../../../core/pipes/list-animation.directive';
import { NumberAnimationDirective } from '../../../core/pipes/number-animation.directive';


@Component({
  selector: 'app-status-and-numbers',
  standalone: true,
  imports: [CommonModule,ListAnimationDirective,NumberAnimationDirective,NgOptimizedImage],
  templateUrl: './status-and-numbers.component.html',
  styleUrl: './status-and-numbers.component.scss',
})
export class StatusAndNumbersComponent {
  @Input() NumbersAndFacts: NumbersAndFactsDto | undefined;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
}
