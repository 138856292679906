import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ContentPagesServices } from '../../services/content-page-services.service';
import { BreadCrumbsDto, CommonContentService,  } from '../../../api/Client';

@Component({
  selector: 'app-bread-crumb',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './bread-crumb.component.html',
  styleUrl: './bread-crumb.component.scss',
})
export class BreadCrumbComponent implements OnInit {
  breadcrumbs: BreadCrumbsDto[] = [];

  constructor(
    private client: CommonContentService,
    private _contentPagesServices: ContentPagesServices
  ) {}

  ngOnInit(): void {
    var currentPagePath = this._contentPagesServices.getPathForCurrentPage();
    this.client.breadCrumbWidget(currentPagePath).subscribe({
      next: async (response) => {
        this.breadcrumbs = response;
      },
      complete: () => {
        if (this.breadcrumbs.length == 0) {
          currentPagePath = this._contentPagesServices.getPathForCurrentPage();
        }
      },
    });
  }
}
