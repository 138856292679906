<div [@fadeIn]>
  <app-page-title-info
    *ngIf="IntroDto"
    [BackgroundImage]="IntroDto.image"
    [Title]="IntroDto.title ?? ''"
    [Description]="IntroDto.description ?? ''"
  ></app-page-title-info>
  <app-projects-filter></app-projects-filter>
  <app-role-in-project
    *ngIf="OurDutiesDto"
    appScrollDetect
    class="hide-fade"
    [OurDuties]="OurDutiesDto"
  ></app-role-in-project>
  <div *ngIf="FrequentlyAskedQuestionsDto" class="know-more-sec">
    <button
      class="know-more-btn"
      [routerLink]="FrequentlyAskedQuestionsDto.clickToAction?.url"
    >
      {{ FrequentlyAskedQuestionsDto.clickToAction?.name }}
      <span class="arrow-left"> </span>
    </button>
  </div>
  <app-page-rating></app-page-rating>
</div>
