<div class="tanmya-location-container">
  <div class="tanmya-location-card">
    <img class="location-gps" [ngSrc]=" MediaUrl + ProjectLocation?.image?.src" width="238" height="238"/>
    <div class="location-details">
      <span class="title">{{ ProjectLocation?.intro }}</span>
      <span class="sub-title"> {{ ProjectLocation?.description }} </span>
      <div class="direction-sec">
        <span
          class="location-icon"
        ></span>
        <span class="direction-text">{{ ProjectLocation?.address }}</span>
      </div>
      <a [href]="ProjectLocation?.clickToAction?.url" [target]="ProjectLocation?.clickToAction?.target">
        <button class="btn-direction">
          <span class="direction-icon"></span>
        {{ ProjectLocation?.clickToAction?.name }}
        </button>
      </a>
    </div>
  </div>
</div>
