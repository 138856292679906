<div  [@fadeIn]>
<app-page-title-info
  *ngIf="IntroDto"
  [BackgroundImage]="IntroDto?.image"
  [Title]="IntroDto?.title ?? undefined"
  [Description]="IntroDto?.description ?? undefined"
  [TagText]="IntroDto?.region?.name ?? undefined"
  [TagIcon]="'/assets/images/location_icon.svg'"
></app-page-title-info>
<app-about-project *ngIf="AboutProjectDto" [AboutProject]="AboutProjectDto"></app-about-project>
<app-project-status-numbers
  *ngIf="NumbersAndFactsDto"
  [NumbersAndFacts]="NumbersAndFactsDto"
></app-project-status-numbers>
<app-tanmya-location appScrollDetect class="hide-fade"
  *ngIf="ProjectLocationDto"
  [ProjectLocation]="ProjectLocationDto"
></app-tanmya-location>

<app-page-rating></app-page-rating>
</div>
