<div class="executive-managment-sec">
  <h6 class="title">
    {{ ExecutiveManagement?.title }}
  </h6>
  <div class="sectors-list row" appListAnimation listClassName="sector-card">

    <div class="col-lg-3"  *ngFor="let item of ExecutiveManagement?.items; let i =index">
      <div class="sector-card list-item-hide">
        <img  [ngSrc]="MediaUrl+item.image?.src" class="sector-icon" width="24" height="24"/>
        <span class="sector-name">{{item.title}}</span>
      </div>
    </div>

  </div>
</div>
