import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BoardMembersDto } from '../../../../api/Client';
import { ListAnimationDirective } from '../../../core/pipes/list-animation.directive';


@Component({
  selector: 'app-board-members',
  standalone: true,
  imports: [CommonModule,ListAnimationDirective,NgOptimizedImage],
  templateUrl: './board-members.component.html',
  styleUrl: './board-members.component.scss',
})
export class BoardMembersComponent {
  @Input() BoardMembers: BoardMembersDto | undefined;
   public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
}
