<div class="status-numbers row">
  <div class="intro-sec col-lg-5">
    <span class="title">
      {{ NumbersAndFacts?.intro }}
    </span>
    <span class="sub-title">
      {{ NumbersAndFacts?.title }}
    </span>
    <span class="content">
      {{ NumbersAndFacts?.description }}
    </span>
  </div>
  <div class="info-sec col-lg-7">
    <div class="row" appListAnimation listClassName="status-card">
      <div class="status-card col-md-6 list-item-hide" *ngFor="let item of NumbersAndFacts?.items; let i = index">
        <img class="numbers-icon" [ngSrc]="MediaUrl+item.icon?.src" [alt]="item.icon?.name" width="24" height="24"/>
        <div class="numb-info-sec">
          <span class="number" appNumberAnimation>{{item.count}}</span>
          <span class="info">{{item.brief}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
