import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UmbracoTranslatePipe } from "../../../core/pipes/umbraco-translate.pipe";
import { environment } from '../../../../environments/environment';
import { AboutProjectDto } from '../../../../api/Client';
import { SanitizedHtmlPipe } from "../../../core/pipes/sanitized-html.pipe";

@Component({
  selector: 'app-about-project',
  standalone: true,
  imports: [CommonModule, UmbracoTranslatePipe, SanitizedHtmlPipe,NgOptimizedImage],
  templateUrl: './about-project.component.html',
  styleUrl: './about-project.component.scss',
})
export class AboutProjectComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer) {}
  ngOnInit(): void {
    this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.AboutProject?.description??"");
  }
  @Input() AboutProject: AboutProjectDto | undefined;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  public safeHtmlContent: SafeHtml ="";
}
