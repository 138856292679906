<div *ngIf="PartnersObject" class="partners-sec">
    <div class="partners-info">
        <div class="partner-content">
            <span class="title">
                {{PartnersObject.title}}
            </span>
            <span class="info">
                {{PartnersObject.brief}}
            </span>
        </div>
    </div>
    <div class="partners-list" appListAnimation listClassName="partner-item">
      @for (item of PartnersObject.partners; track item.icon?.name) {
      <a [target]="item.navigation?.target" [href]="item.navigation?.url" > <img [alt]="item.icon?.name" [title]="item.icon?.name" [ngSrc]="MediaUrl+item.icon?.src" width="135" height="135" class="partner-item list-item-hide"></a>
      }
    </div>
</div>
