<div class="role-project-container">
  <div class="role-project-header">
    <span class="title">
      {{ OurDuties?.title }}
    </span>
    <span class="sub-title">
      {{ OurDuties?.description }}
    </span>
  </div>
  <div class="roles-list row grid gap-0 row-gap-lg-5" appListAnimation listClassName="role-card">
    <div *ngFor="let member of OurDuties?.items; let i =index" [class]="'col-lg-6 d-flex justify-content-' + (i %2 === 0 ? 'end' : 'start' )+''">
      <div class="role-card list-item-hide">
        <span class="role-number"> 0{{i+1}}</span>
        <span class="role-content"> {{member.title}}</span>
      </div>
    </div>
  </div>
</div>
