import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ValuesDto } from '../../../../api/Client';
import { environment } from '../../../../environments/environment';
import { ListAnimationDirective } from '../../../core/pipes/list-animation.directive';
import { SanitizedHtmlPipe } from "../../../core/pipes/sanitized-html.pipe";


@Component({
  selector: 'app-departments-goals',
  standalone: true,
  imports: [CommonModule, ListAnimationDirective, SanitizedHtmlPipe,NgOptimizedImage],
  templateUrl: './departments-goals.component.html',
  styleUrl: './departments-goals.component.scss',
})
export class DepartmentsGoalsComponent {
  @Input() Values: ValuesDto | undefined;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
}
