<div class="branches-maps-container">
<div class="header">
  <h4 class="title">{{ ourLocationsDto.title }}</h4>
  <div class="choose-branch-sec">
    <label for=""> {{ "chooseBranch" | umbracoTranslate | async }} </label>
    <select (change)="changeBracnhes($event)" class="dropdown custom-select">
      <option [value]="null" selected>
        {{ "AllBracnhes" | umbracoTranslate | async }}
      </option>

      <option
        *ngFor="let item of ourLocationsDto.locations; let i = index"
        [value]="i"
      >
        {{ item.title }}
      </option>
    </select>
  </div>
</div>

<google-map
  height="100%"
  width="100%"
  [center]="center"
  [zoom]="zoom"
  (mapClick)="moveMap($event)"
  (mapMousemove)="move($event)"
  [options]="mapOptions"
>
  <!-- Custom Marker -->
  <map-marker
    *ngFor="let item of ourLocationsDto.locations; let i = index"
    [position]="{
      lat: toNumber(item.latitude ?? '0'),
      lng: toNumber(item.longitude ?? '0')
    }"
    #markerElem="mapMarker"
    [title]="item.title!"
    [options]="markerOptions"
    (mapClick)="onMarkerClick(item,markerElem)"

  ></map-marker>
  <map-info-window #infoWindow [innerHTML]="infoContent | sanitizedHtml" ></map-info-window>
</google-map>
</div>