import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreadCrumbComponent } from '../../../layout/bread-crumb/bread-crumb.component';
import { MediaFileDto } from '../../../../api/Client';


@Component({
  selector: 'app-page-title-info',
  standalone: true,
  imports: [CommonModule, RouterModule,BreadCrumbComponent],
  templateUrl: './page-title-info.component.html',
  styleUrl: './page-title-info.component.scss',
})
export class PageTitleInfoComponent {
  @Input() EnableBreadCrumb: boolean = true;
  @Input() BackgroundImage: MediaFileDto | undefined = undefined;
  @Input() Title: string | undefined = undefined;
  @Input() Description: string | undefined = undefined;
  @Input() TagText: string | undefined = undefined;
  @Input() TagIcon: string | undefined = undefined;

  constructor() {}
}
