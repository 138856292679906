<div class="about-project-container">
  <div class="row grid gap-0 justify-content-between mw-23k">
    <div class="project-info-sec col-lg-6">
      <div class="header">
        <span class="title">{{ AboutProject?.intro }}</span>
        <h5 class="sub-title">{{ AboutProject?.title }}</h5>
      </div>

      <div class="project-desc" [innerHTML]="safeHtmlContent  | sanitizedHtml"></div>

      <a *ngIf="AboutProject?.website?.url != null"
        [href]="AboutProject?.website?.url"
        [target]="AboutProject?.website?.target"
        class="project-link"
      >
        {{ AboutProject?.website?.name }}<span class="link-open"></span>
      </a>
    </div>

    <div class="project-gallery col-lg-6">
      <div id="carouselProjectImages" class="carousel slide carousel-fade">
        <div class="carousel-inner">
          <div
            *ngFor="let iamge of AboutProject?.images; let i = index"
            class="carousel-item"
            [ngClass]="{ active: i === 0 }"
          >
            <img [ngSrc]="MediaUrl + iamge.src" class="d-block w-100" alt="..." width="430" height="378"/>
            <div class="carousel-caption d-block">
              <span class="index-numbers">{{ AboutProject?.images?.length }} / {{ i + 1 }}</span>
            </div>
          </div>
        </div>

        <!-- Custom Controls with Index -->
        <div class="carousel-controls-container">
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselProjectImages"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">{{ 'next' | umbracoTranslate | async}}</span>
          </button>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselProjectImages"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">{{ 'previous' | umbracoTranslate | async}}</span>
          </button>

        </div>
      </div>
    </div>
  </div>
</div>
