import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ExecutiveManagementDto } from '../../../../api/Client';
import { environment } from '../../../../environments/environment';
import { ListAnimationDirective } from '../../../core/pipes/list-animation.directive';


@Component({
  selector: 'app-executive-managment',
  standalone: true,
  imports: [CommonModule,ListAnimationDirective,NgOptimizedImage],
  templateUrl: './executive-managment.component.html',
  styleUrl: './executive-managment.component.scss',
})
export class ExecutiveManagmentComponent {
  @Input() ExecutiveManagement: ExecutiveManagementDto | undefined;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
}
