<nav aria-label="breadcrumb">
  @if (breadcrumbs.length > 0) {
  <ol class="breadcrumb">
    @for (item of breadcrumbs; track $index; let last = $last; let first = $first;) {

    <li class="breadcrumb-item" [class]="{ 'active text-white': last }">
      <a [class]="{ 'home-icon': first ,'pointer': item.navigatable, 'default': !item.navigatable }" [routerLink]="item.navigatable ?item.url : null">
        {{ item.name }}
      </a>
    </li>
    }
  </ol>
  }
</nav>
