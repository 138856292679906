import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { OurRoleDto } from '../../../../api/Client';
import { environment } from '../../../../environments/environment';
import { fadeInAnimation } from '../../../core/pipes/router-transition-animation';
import { SanitizedHtmlPipe } from "../../../core/pipes/sanitized-html.pipe";

@Component({
  selector: 'app-slider-mission-goals',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, SanitizedHtmlPipe],
  templateUrl: './slider-mission-goals.component.html',
  styleUrl: './slider-mission-goals.component.scss',
  animations: [fadeInAnimation]
})
export class SliderMissionGoalsComponent implements OnInit {
  ngOnInit(): void {
    // this is fixed we have only three for now
    this.titles!.push(this.Roles?.title);
    this.titles.push(this.Roles?.ourMission?.title);
    this.titles.push(this.Roles?.ourGoal?.title);

    this.paragraphs.push(this.Roles?.description);
    this.paragraphs.push(this.Roles?.ourMission?.description);
    this.paragraphs.push(this.Roles?.ourGoal?.description);

    this.images.push({
      src: this.MediaUrl + this.Roles?.image?.src,
      name: this.Roles?.image?.name,
    });
    this.images.push({
      src: this.MediaUrl + this.Roles?.ourMission?.image?.src,
      name: this.Roles?.ourMission?.image?.name,
    });
    this.images.push({
      src: this.MediaUrl + this.Roles?.ourGoal?.image?.src,
      name: this.Roles?.ourGoal?.image?.name,
    });
  }

  @Input() Roles: OurRoleDto | null = null;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;

  // List of indicators, paragraphs, and images
  indicators = ['—', '—', '—']; // Vertical dashes
  titles: any = [];
  paragraphs: any = [];
  images: any = [];

  // Track the active index
  activeIndex = 0;

  // Set the active index when an indicator (or paragraph) is clicked
  setActive(index: number) {
    this.activeIndex = index;
  }
}
