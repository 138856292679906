import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { OurDutiesDto } from '../../../../api/Client';
import { environment } from '../../../../environments/environment';
import { ListAnimationDirective } from '../../../core/pipes/list-animation.directive';


@Component({
  selector: 'app-role-in-project',
  standalone: true,
  imports: [CommonModule,ListAnimationDirective],
  templateUrl: './role-in-project.component.html',
  styleUrl: './role-in-project.component.scss',
})
export class RoleInProjectComponent {
  @Input() OurDuties: OurDutiesDto | undefined;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
}
