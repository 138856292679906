<div class="project-status-numbers-container">
  <div class="header">
    <span class="title">{{ NumbersAndFacts?.intro }}</span>
    <span class="project-name">{{ NumbersAndFacts?.description }}</span>
  </div>
  <div class="charts-list row" appListAnimation listClassName="chart-card">

    <div *ngFor="let item of NumbersAndFacts?.items; let i = index" class="col-sm-12 col-md-6 col-lg">
      <div class="chart-card list-item-hide">
        <span class="chart-title"> {{item.brief}}</span>
        <span class="chart-number" appNumberAnimation> {{item.count}} </span>
        <img [ngSrc]="MediaUrl + item.icon?.src" class="chart-img" width="233" height="73"/>
      </div>
    </div>

  </div>
</div>
