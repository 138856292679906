import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, forkJoin, of } from 'rxjs';
import {
  CommonContentService,
  FrequentlyAskedQuestionsDto,
  GrowthProjectsPageContentService,
  IntroDto,
  NumbersAndFactsDto,
  OurDutiesDto,
} from '../../../api/Client';
import { environment } from '../../../environments/environment';
import { ContentPagesServices } from '../../services/content-page-services.service';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';

@Component({
  selector: 'app-growth-projects',
  templateUrl: './growth-projects.component.html',
  styleUrl: './growth-projects.component.scss',
  standalone: false,
  animations: [fadeInAnimation]
})
export class GrowthProjectsComponent implements OnInit, OnDestroy {
  public NumbersAndFactsDto: NumbersAndFactsDto | undefined;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  public OurDutiesDto: OurDutiesDto | undefined;
  public IntroDto: IntroDto | undefined;
  public FrequentlyAskedQuestionsDto: FrequentlyAskedQuestionsDto | undefined;
  constructor(
    private _contentPagesServices: ContentPagesServices,
    private _client: GrowthProjectsPageContentService,
    private _common: CommonContentService,
    private _cotnentLoadingService: CotnentLoadingService
  ) {}

  ngOnInit(): void {
    var currentPagePath = this._contentPagesServices.getPathForCurrentPage();
    forkJoin({
      intoDto: this._common.introText(currentPagePath),
      ourDutiesDto: this._client.ourDuties(currentPagePath).pipe(
        catchError((error) => {
          return of(null);
        })
      ),
      faq: this._client.frequentlyAskedQuestions2(currentPagePath).pipe(
        catchError((error) => {
          return of(null);
        })
      ),
    }).subscribe(async ({ intoDto, ourDutiesDto, faq }) => {
      this.IntroDto = intoDto;
      this.OurDutiesDto = ourDutiesDto!;
      this.FrequentlyAskedQuestionsDto = faq!;
      this._cotnentLoadingService.setLoadingState(true);
    });
  }
  ngOnDestroy(): void {
    this._cotnentLoadingService.setLoadingState(false);
  }
}
