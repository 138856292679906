
<app-bread-crumb *ngIf="EnableBreadCrumb"></app-bread-crumb>

@if (Title) {
<div
  class="info-sec"
  [style]="
    'background-image: url(\''+ (BackgroundImage == null ?'/assets/images/side_info_card.svg' : BackgroundImage.src) +'\');background-repeat: no-repeat;'
  "
>
  <div class="content">
    <h2 class="title">{{Title}}</h2>
    <p class="info">
      {{Description}}
    </p>
    @if (TagText) {
    <div class="location">
      <span class="location-icon"></span>
      <span class="location-name"> {{TagText}}</span>
    </div>
  }
  </div>

</div>
}
