import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {  ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';



import { PageKeysDto, CountRatingPerNodeDto, NodeRatingDto, NodeRatingService, ContentRate, CreateUpdateNodeRatingDto } from '../../../../api/Client';
import { UmbracoTranslatePipe } from '../../../core/pipes/umbraco-translate.pipe';
import { ContentPagesServices } from '../../../services/content-page-services.service';
import { TranslationService } from '../../../services/translation.service';

@Component({
  selector: 'app-page-rating',
  standalone: true,
  imports: [CommonModule, UmbracoTranslatePipe],
  providers: [],
  templateUrl: './page-rating.component.html',
  styleUrl: './page-rating.component.scss',
})
export class PageRatingComponent implements OnInit {
  private sessionId: string;
  private pagesKeys: PageKeysDto | null = null;
  public CurrentNodeInfo: CountRatingPerNodeDto | null = null;
  public CurrentNodeRating: NodeRatingDto | null = null;
  public ratingCount: number = 0;
  public totalContentCount: string = '';
  constructor(
    private _client: NodeRatingService,
    private _contentPagesServices: ContentPagesServices,
    private _toastr: ToastrService,
    public _translationService: TranslationService
  ) {
    this.sessionId = _contentPagesServices.getSessionId();
  }
  async ngOnInit() {
    await this.getRatings();
    await this.getMyRatings();
  }

  async getRatings() {
    this.pagesKeys = await this._contentPagesServices.getKeyForCurrentPage();
    this._client.nodeRatings(this.pagesKeys!.key ?? '').subscribe({
      next: (response) => {
        this.CurrentNodeInfo = response;
      },
      complete: async () => {
        this.totalContentCount = await this._translationService.Translate(
          'totalCotnentRatingCount',
          [this.CurrentNodeInfo?.usefulRatingCount ?? 0]
        );
      },
    });
  }
  async Translate(key: string) {
    return this._translationService.Translate(key);
  }

  async getMyRatings() {
    this._client
      .myNodePagesRatings(this.pagesKeys?.key, this.sessionId)
      .subscribe({
        next: (response) => {
          this.CurrentNodeRating = response;
        },
      });
  }

  async showToast() {
    var value = await this._translationService.Translate('ratingAdded');
    this._toastr.success('', value);
  }

  async Rate(rate: ContentRate) {
    if (
      this.CurrentNodeRating != null &&
      this.CurrentNodeRating.rating == rate
    ) {
      // do nothing its just a double click
      await lastValueFrom(
        this._client.nodeRatingDELETE(this.CurrentNodeRating?.id ?? '')
      );
      await this.getRatings();
      this.CurrentNodeRating = null;
    } else if (this.CurrentNodeRating == null) {
      // first time to rate we need to post

      this._client
        .nodeRatingPOST(
          CreateUpdateNodeRatingDto.fromJS({
            nodeId: this?.pagesKeys?.key ?? '',
            rating: rate,
            sessionId: this?.sessionId ?? '',
            clientIPAddress: '',
            clientLocation: '',
          })
        )
        .subscribe({
          next: (response) => {},
          complete: async () => {
            this.getMyRatings();
            await this.showToast();
            await this.getRatings();
          },
        });
    } else {
      // Its an update

      this._client
        .nodeRatingPUT(
          this.CurrentNodeRating?.id ?? '',
          CreateUpdateNodeRatingDto.fromJS({
            nodeId: this.pagesKeys?.key ?? '',
            rating: rate,
            sessionId: this.sessionId,
            clientIPAddress: '',
            clientLocation: '',
          })
        )
        .subscribe({
          next: (response) => {},
          complete: async () => {
            this.getMyRatings();
            await this.showToast();
            await this.getRatings();
          },
        });
    }
  }
}
