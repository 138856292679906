import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { debounceTime, fromEvent, map } from 'rxjs';
import { UmbracoTranslatePipe } from '../../../core/pipes/umbraco-translate.pipe';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import { GrowthProjectItemDto, GrowthProjectsPageContentService, LookupDto, LookupsContentService } from '../../../../api/Client';
import { TranslationService } from '../../../services/translation.service';
import { environment } from '../../../../environments/environment';
import { ListAnimationDirective } from '../../../core/pipes/list-animation.directive';

@Component({
  selector: 'app-projects-filter',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    UmbracoTranslatePipe,
    NgxSpinnerComponent,ListAnimationDirective,NgOptimizedImage
  ],
  templateUrl: './projects-filter.component.html',
  styleUrl: './projects-filter.component.scss',
})
export class ProjectsFilterComponent implements OnInit {
  @ViewChild('debounceInput', { static: true }) debounceInput!: ElementRef;
  @ViewChild('projectsList') projectsList!: ElementRef;

  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  public GrowthProjectItemDto: GrowthProjectItemDto[] = [];
  public RegionDto: LookupDto[] = [];
  public TotalCount: number = 0;
  public CurrentCount: number = 0;
  public SearchTerm: string = '';
  public RegionId: string | null = null;
  public Loading: boolean = false;

  constructor(
    private _client: GrowthProjectsPageContentService,
    private _lokup: LookupsContentService,
    public _translationService: TranslationService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.Loading = true;
    this._lokup.lookups('region').subscribe({
      next: async (response) => {
        this.RegionDto = response;
      },
      error: () => {
        this.spinner.hide();
      },
    });
    this.SearchProjects(this.SearchTerm!, this.RegionId!);

    fromEvent(this.debounceInput.nativeElement, 'input')
      .pipe(
        debounceTime(500), // half a sec
        map((event: any) => event.target.value)
      )
      .subscribe((value: string) => {
        this.Search({});
      });
  }

  onOptionChange(event: any) {
    this.CurrentCount = 0;
    this.GrowthProjectItemDto = [];
    this.SearchProjects(this.SearchTerm!, this.RegionId!);
  }
  Search(event: any) {
    this.CurrentCount = 0;
    this.GrowthProjectItemDto = [];
    this.SearchProjects(this.SearchTerm!, this.RegionId!);
  }

  LoadMore(event: any) {
    this.SearchProjects(this.SearchTerm!, this.RegionId!);
  }

  //projects(searchTerm?: string | undefined, regionId?: string | undefined, skipCount?: number | undefined, maxResultCount?: number | undefined)
  private SearchProjects(searchTerm: string, regionId: string | undefined) {
    this.Loading = true;
    this.spinner.show();
    this._client
      .projects(
        searchTerm!,
        regionId! == 'null' ? undefined : regionId! ?? '',
        this.CurrentCount!,
        6
      )
      .subscribe({
        next: async (result) => {
          this.GrowthProjectItemDto.push(...result.items!);
          this.TotalCount = result.totalCount!;
          this.CurrentCount += result.items!.length;
          setTimeout(() => {
          //  this.showPartners();
          }, 500);
        },
        complete: () => {
          this.Loading = false;
          this.spinner.hide();
          window.scrollBy({ top: +50, behavior: 'smooth' });

        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

}
